.Library {
  position: relative;
}

.Library ul {
  display: flex;
  flex-wrap: wrap;
}

.Library li {
  flex-grow: 0;
}
.Library li:hover {
  background: #aab7c4;
}
.Library li:active {
  opacity: 0.75;
}

.Library .category {
  flex-basis: 100%;
  background: #e6ebf1 !important;
  margin: 5px 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 85%;
}

.Library .category:first-child {
  margin-top: 0;
}

.Library .dragOverlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background: rgba(170, 183, 196, 0.75);
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 30px;
  font-weight: 300;

  pointer-events: none;
}