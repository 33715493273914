* {
  margin: 0;
  padding: 0;
  font: inherit;
  list-style: none;
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
}

body {
  font: 14px -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  
  background: #f6f9fc;
}