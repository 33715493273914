.Canvas {
  flex: 1;
  width: 100%;
  position: relative;

  background: white;
  border-radius: 4px;
  box-shadow: 0 3px 12px #cfd7df;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Canvas canvas {
  margin: 20px;
}

.Canvas .small {
  width: 22px;
  height: 22px;
}