.Loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #aab7c4;
  font-size: 30px;
  font-weight: 300;
}

.Loading progress {
  width: 200px;
  margin-top: 20px;
}