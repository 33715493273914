* {
  margin: 0;
  padding: 0;
  font: inherit;
  list-style: none;
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
}

body {
  font: 14px -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  
  background: #f6f9fc;
}
.App {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
}

.App > header {
  text-align: center;
  color: #aab7c4;
  font-size: 12px;
  margin: 15px 20px 0;
}

.App > header span {
  margin: 0 20px;
}

.App > main {
  display: flex;
}
.Panel {
  flex: 1 0 1px;

  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Panel + .Panel {
  margin-left: 0;
}

.Panel.preview {
  flex: 0 0 400px;
}

.Panel > footer {
  margin-top: 10px;
}

.Panel > footer p {
  color: #aab7c4;
  font-size: 12px;
  margin: 8px;
}
.Loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #aab7c4;
  font-size: 30px;
  font-weight: 300;
}

.Loading progress {
  width: 200px;
  margin-top: 20px;
}
.List {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.List ul {
  overflow: auto;
}

.List li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 4px;
}

.List .dragged {
  opacity: 0.33;
}

.List img.asset {
  width: 64px;
  height: 64px;
  background: -webkit-radial-gradient(center, circle, rgba(251, 228, 125, 0.5) 62%, transparent 63%);
  background: radial-gradient(circle at center, rgba(251, 228, 125, 0.5) 62%, transparent 63%);
}

.List .flipped img.asset {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
}
.Library {
  position: relative;
}

.Library ul {
  display: flex;
  flex-wrap: wrap;
}

.Library li {
  flex-grow: 0;
}
.Library li:hover {
  background: #aab7c4;
}
.Library li:active {
  opacity: 0.75;
}

.Library .category {
  flex-basis: 100%;
  background: #e6ebf1 !important;
  margin: 5px 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 85%;
}

.Library .category:first-child {
  margin-top: 0;
}

.Library .dragOverlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background: rgba(170, 183, 196, 0.75);
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 30px;
  font-weight: 300;

  pointer-events: none;
}
.Layers li {
  background: #e6ebf1;
  margin-bottom: 5px;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
}

.Layers span {
  flex-grow: 1;
  text-transform: capitalize;
  margin: 0 10px;
}

.Layers .dragged {
  opacity: 0.5;
}

.Layers li > div {
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Layers .adjustments {
  background: rgba(0, 0, 0, 0.05);
}
.Button {
  display: inline-block;
  padding: 7px 10px;

  background: none;
  border: 1px solid #aab7c4;
  border-radius: 4px;

  text-transform: uppercase;
  font-weight: bold;
  font-size: 85%;

  cursor: default;
  outline: none;
}

.Button + .Button {
  margin-left: 10px;
}

.Button:hover {
  color: white;
  background: #aab7c4;
}

.Button:active {
  opacity: 0.75;
}

.Button img {
  width: 24px;
  height: 24px;
  margin: -7px;
}
.LayerAdjustment {
  display: flex;
  font-size: 12px;
}

.LayerAdjustment + .LayerAdjustment {
  margin-left: 10px;
}

.LayerAdjustment label {
  margin: 2px 5px 0 0;
}

.LayerAdjustment input {
  width: 100%;
  flex: 1 1;
  border-radius: 2px;
  border: none;
  padding: 2px 0 2px 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.Canvas {
  flex: 1 1;
  width: 100%;
  position: relative;

  background: white;
  border-radius: 4px;
  box-shadow: 0 3px 12px #cfd7df;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Canvas canvas {
  margin: 20px;
}

.Canvas .small {
  width: 22px;
  height: 22px;
}
.Title {
  margin: 40px;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  text-align: center;
}
