.Button {
  display: inline-block;
  padding: 7px 10px;

  background: none;
  border: 1px solid #aab7c4;
  border-radius: 4px;

  text-transform: uppercase;
  font-weight: bold;
  font-size: 85%;

  cursor: default;
  outline: none;
}

.Button + .Button {
  margin-left: 10px;
}

.Button:hover {
  color: white;
  background: #aab7c4;
}

.Button:active {
  opacity: 0.75;
}

.Button img {
  width: 24px;
  height: 24px;
  margin: -7px;
}