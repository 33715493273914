.Layers li {
  background: #e6ebf1;
  margin-bottom: 5px;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
}

.Layers span {
  flex-grow: 1;
  text-transform: capitalize;
  margin: 0 10px;
}

.Layers .dragged {
  opacity: 0.5;
}

.Layers li > div {
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Layers .adjustments {
  background: rgba(0, 0, 0, 0.05);
}